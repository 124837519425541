import React from 'react'
import Layout from "../../components/App/Layout"
import Navbar from "../../components/App/Navbar"
import ContentBanner from '../../components/Common/ContentBanner'
import Footer from "../../components/App/Footer"
import ECommerceCampaign from '../../components/Examples/ECommerceCampaign'
import Helmet from 'react-helmet';
import ProjectStartArea from '../../components/Common/StartProject';
import SEO from '../../components/App/SEO';
import exampleMetaImage from '../../assets/images/examples/coupon/coupon.jpg';

const Examples = () => {

  return (
	<Layout>
	  <SEO
		title="ECサイトキャンペーンの事例・サンプル"
		desc="リタッチではECサイトで使用されるよく使用されるキャンペーンについての事例やサンプルが多くあります。"
		banner={exampleMetaImage}
		pathname={'/examples/e-commerce'}
		type={'Article'}
		shortName="プリアド・プリセーブキャンペーン"
		createdAt="2021-03-02"
	  />
	  <Navbar />
	  <ContentBanner
		  pageTitle="ECサイト"
		homePageText="Home"
		homePageUrl="/"
		activePageText="ECサイト"
		subDirectories={[{
		  url: '/examples',
		  name: '事例・サンプル'
		}]}
	  />
	  <ECommerceCampaign />
	  <ProjectStartArea />
	  <Footer />
	</Layout>
  );
}

export default Examples
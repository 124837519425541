// Hook
import React,{useEffect, useState} from 'react';
import useScript from '../../helpers/useScript';

const Widget = (props)=> {
  useScript(
	"https://retach.biz/scripts/promotionCall.js", props.visible, props.id
  );
  return (
	<div id={'embed'}>
	</div>
  )
}

export default Widget
import { useState, useEffect } from "react";

export default function useScript(src, visible, promotionId) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(
	() => {
	  if (!src || visible === false) {
		setStatus("idle");
		const element = document.getElementById('embed')
		while (element.firstChild) {
		  element.removeChild(element.firstChild);
		}
		return;
	  }
	  // Fetch existing script element by src
	  // It may have been added by another intance of this hook
	  let a = document.createElement('a')
	  a.setAttribute('data-retach-widget', 'embed')
	  a.setAttribute('href', `https://retach.biz/promotions/${promotionId}`)
	  a.setAttribute('rel', 'nofollow')
	  document.getElementById('embed').prepend(a)
	  // Create script
	  let script = document.querySelector(`script[src="${src}"]`);
	  script = document.createElement("script");
	  script.src = src;
	  script.async = true;
	  script.setAttribute("data-status", "loading");
	  document.body.appendChild(script);
	},
	[visible] // Only re-run effect if script src changes
  );
  return status;
}

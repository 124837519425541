import styled from 'styled-components';
export default styled.div` 
	width: 100%;
    height: 100%;
    position: relative;
    z-index: 1;
    overflow: auto;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
	.ant-drawer-wrapper-body {
		display: flex;
		flex-direction: column;
		flex-wrap: nowrap;
		width: 100%;
		height: 100%;
	}
	.ant-drawer-header {
		position: relative;
		padding: 16px 24px;
		border-bottom: 1px solid #f0f0f0;
		border-radius: 2px 2px 0 0;
		.ant-drawer-header-title {
			display: flex;
			align-items: center;
			justify-content: space-between;
			.ant-drawer-title {
				margin: 0;
				color: #000000d9;
				font-weight: 500;
				font-size: 16px;
				line-height: 22px;
			}
		}
		.ant-drawer-header-no-title {
		  color: rgba(0,0,0,.85);
		  background: #fff;
		}
	}
	.ant-drawer-body {
	  flex-grow: 1;
	  padding: 24px;
	  overflow: auto;
	  font-size: 14px;
	  line-height: 1.5715;
	  word-wrap: break-word;
	}
	.ant-drawer-close {
		display: inline-block;
		margin-right: 12px;
		color: #00000073;
		font-weight: 700;
		font-size: 16px;
		font-style: normal;
		line-height: 1;
		text-align: center;
		text-transform: none;
		text-decoration: none;
		background: 0 0;
		border: 0;
		outline: 0;
		cursor: pointer;
		transition: color .3s;
		text-rendering: auto;
	}
`;

import React from 'react';
import {StaticImage} from 'gatsby-plugin-image';

const Partner = () => {
    return (
        <div className="partner-area pt-100 pb-70">
            <div className="container">
                <div className="row align-items-center">


                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <StaticImage src={'../../assets/images/examples/eCommerce/rakuten.jpg'} alt="rakuten" />
                                </div>
                            </div>
                    </div>
                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <StaticImage src={'../../assets/images/examples/eCommerce/base.jpg'} alt="base" />
                            </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <StaticImage src={'../../assets/images/examples/eCommerce/shopify.jpg'} alt="shopify" />
                            </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <StaticImage src={'../../assets/images/examples/eCommerce/welcart.jpg'} alt="welcart" />
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <StaticImage src={'../../assets/images/examples/eCommerce/woocommerce.jpg'} alt="woocommerce" />
                                </div>
                            </div>
                    </div>

                    <div className="col-lg-2 col-6 col-sm-4 col-md-4">
                            <div className="single-partner-item">
                                <div className="single-partner-item">
                                    <StaticImage src={'../../assets/images/examples/eCommerce/colorme.jpg'} alt="colorme" />
                                </div>
                            </div>
                    </div>

                    {/*<div className="col-lg-2 col-6 col-sm-4 col-md-4">*/}
                    {/*        <div className="single-partner-item">*/}
                    {/*            <div className="single-partner-item">*/}
                    {/*                <StaticImage src={'../../assets/images/examples/eCommerce/makeshop.jpg'} alt="makeshop" />*/}
                    {/*            </div>*/}
                    {/*        </div>*/}
                    {/*</div>*/}
                </div>
            </div>

            <div className="divider"></div>
        </div>
    )
};

export default Partner
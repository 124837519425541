// Hook
import React,{useEffect, useState} from 'react';

function useScript(src, visible, id) {
  // Keep track of script status ("idle", "loading", "ready", "error")
  const [status, setStatus] = useState(src ? "loading" : "idle");

  useEffect(
	() => {
	  if (!src || visible === false) {
		setStatus("idle");
		const element = document.getElementById('embed-gallery')
		while (element.firstChild) {
		  element.removeChild(element.firstChild);
		}
		return;
	  }
	  // Fetch existing script element by src
	  // It may have been added by another intance of this hook
	  let div = document.createElement('div')
	  div.setAttribute('data-gallery-import', id)
	  document.getElementById('embed-gallery').prepend(div)
	  // Create script
	  let script = document.querySelector(`script[src="${src}"]`);
	  script = document.createElement("script");
	  script.src = src;
	  script.async = true;
	  script.setAttribute("data-status", "loading");
	  document.body.appendChild(script);
	},
	[visible] // Only re-run effect if script src changes
  );
  return status;
}

const Gallery = (props)=> {
  useScript(
	"https://retach.biz/scripts/galleryCall.js", props.visible, props.id
  );
  return (
	<div id={'embed-gallery'}>
	</div>
  )
}

export default Gallery
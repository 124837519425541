import React, {useState} from 'react';
import Drawer from 'react-modern-drawer';
import Widget from './widget';
import {StaticImage} from 'gatsby-plugin-image';
import 'react-modern-drawer/dist/index.css'
import './drawer.css'
import StyleWrapper from './drawer.style'
import Gallery from './gallery';
export default (props)=> {
  const {drawerTitle, setVisible, visible, id, type='widget'} = props
  return (
	<Drawer
	  open={visible}
	  onClose={() =>setVisible(false)}
	  direction="right"
	  zIndex={1000}
	>
	  <StyleWrapper >
		<div className="ant-drawer-wrapper-body">
		  <div className="ant-drawer-header">
			<div className="ant-drawer-title">
			  <div className="ant-drawer-header-title">
				{/*<button type="button" aria-label="Close" className="ant-drawer-close">*/}
				{/*  <span role="img" aria-label="close" className="anticon anticon-close">*/}
				{/*	<svg viewBox="64 64 896 896" focusable="false" data-icon="close" width="1em" height="1em" fill="currentColor" aria-hidden="true"><path d="M563.8 512l262.5-312.9c4.4-5.2.7-13.1-6.1-13.1h-79.8c-4.7 0-9.2 2.1-12.3 5.7L511.6 449.8 295.1 191.7c-3-3.6-7.5-5.7-12.3-5.7H203c-6.8 0-10.5 7.9-6.1 13.1L459.4 512 196.9 824.9A7.95 7.95 0 00203 838h79.8c4.7 0 9.2-2.1 12.3-5.7l216.5-258.1 216.5 258.1c3 3.6 7.5 5.7 12.3 5.7h79.8c6.8 0 10.5-7.9 6.1-13.1L563.8 512z"></path></svg></span>*/}
				{/*</button>*/}
				<div className="ant-drawer-title">{drawerTitle}</div>
				<div className="ant-drawer-extra">
				  <div className="ant-space ant-space-horizontal ant-space-align-center" style={{gap: '8px'}}>
					<div className="ant-space-item" >
					  <button type="button" className="btn btn-light" onClick={()=>setVisible(false)}>閉じる</button>
					</div>
				  </div>
				</div>
			  </div>
			</div>

			</div>
		  <div className="ant-drawer-body">
			{type==='widget' ?  <div style={{textAlign: 'center'}}>{(id !=='LINE') ? <Widget visible={visible} id={id}/> : <StaticImage src="../../assets/images/examples/widgets/line-retach.jpg" />}</div>
			  : <Gallery id={id} visible={visible}/>
			}

		  </div>
		</div>
	  </StyleWrapper>
	</Drawer>
  )
}

import React, {useState} from 'react';
import {StaticImage} from 'gatsby-plugin-image';
import ExampleWidget from './widgets/ExampleWidget'

export default (props) => {
  const types = props.types || ['lineApp','snsBranding','share','keepWatching','password'].filter(x => !props.exclude || props.exclude.indexOf(x) < 0)
  const title = props.title || 'もっとリタッチを活用する'
  const description = props.description || 'リタッチで用意されているの豊富な応募方法の組み合わせて、最適なキャンペーンを開催できます。'
  return (
	<section className="services-area ptb-100 bg-e3fbff">
	  <div className="container">
		<div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Our Services
                    </span>
		  <h2>{title}</h2>
		  <p>{description}</p>
		</div>

		<div className="row">
		  {types.map(type => {
		    return (
			  <div className={types.length < 3 ? "col-lg-6 ": "col-lg-4 " + "col-md-6 col-sm-12"}>
				<ExampleWidget type={type} size="large" onClick={(id, title) =>props.showDrawer(id, title)}/>
			  </div>
			)
		  })}
		</div>
	  </div>

	</section>
  )
}
import styled from 'styled-components';
const EntryFactsStyle = styled.div`
	.link {
		color: #ff5d22;
		text-decoration: initial;
	}
	.section-title {
		max-width: 810px;
		p {
			max-width: initial;
		}
	}
	.col-lg-2 {
		padding-right: 5px;
		padding-left: 5px;
		.single-services-item-box{
		  height: 95%;
		  padding: 45px 20px 0px 20px;
		  h3 {
		 	font-size: 16px;
		 	margin-bottom: 0; 
		  }
		  .icon img,i{
		  	height: 55px;
		  }
		}
	}
	.attention{
		text-align: right;
		font-size: 14px;
	}
	u {
		font-weight: bold;
	}
	
`;

export default EntryFactsStyle
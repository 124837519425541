import React from 'react'
import { Helmet } from "react-helmet"
import { StaticQuery, graphql } from 'gatsby'

const SEO = ({ title, desc, banner, pathname, type, createdAt, updatedAt, slug, noIndex=false, noFollow=false}) => (
    <StaticQuery
        query={query}
        render={({
                     site: {
                         buildTime,
                         siteMetadata: {
                             defaultTitle,
                             titleAlt,
                             shortName,
                             author,
                             siteLanguage,
                             logo,
                             siteUrl,
                             pathPrefix,
                             defaultDescription,
                             defaultBanner,
                             twitter
                         }
                     }
                 }) => {
            const seo = {
                title: title + ' | ' + defaultTitle || defaultTitle,
                description: desc || defaultDescription,
                image: `${siteUrl}${banner || defaultBanner}`,
                url: `${siteUrl}${pathname || '/'}`,
            };
            const realPrefix = pathPrefix === '/' ? '' : pathPrefix;
            let schemaOrgJSONLD = [
                {
                    '@context': 'http://schema.org',
                    '@type': 'WebSite',
                    '@id': siteUrl,
                    url: siteUrl,
                    name: defaultTitle,
                    alternateName: titleAlt || ''
                }
            ];
            if (type) {
                schemaOrgJSONLD = [
                    {
                        '@context': 'http://schema.org',
                        '@type': type === 'Product' ? 'Article' : type,
                        '@id': seo.url,
                        url: seo.url,
                        name: defaultTitle,
                        alternateName: titleAlt || '',
                        headline: title,
                        image: {
                            '@type': 'ImageObject',
                            url: seo.image
                        },
                        description: seo.description,
                        datePublished: createdAt,
                        dateModified: updatedAt || buildTime,
                        author: {
                            '@type': 'Person',
                            name: author
                        },
                        publisher: {
                            '@type': 'Organization',
                            name: author,
                            logo: {
                                '@type': 'ImageObject',
                                url: siteUrl + realPrefix + logo
                            }
                        },
                        isPartOf: siteUrl,
                        mainEntityOfPage: {
                            '@type': 'WebSite',
                            '@id': siteUrl
                        }
                    }
                ]
                if( type === 'Product' ) {
                    schemaOrgJSONLD.push({
                        '@context': 'http://schema.org',
                        '@type': 'WebApplication',
                        '@id': seo.url,
                        applicationCategory: 'BusinessApplication',
                        name: defaultTitle,
                        operatingSystem: 'all',
                        url: siteUrl,
                        screenshot: seo.image,
                        // offers: {
                        //     '@type': 'AggregateOffer',
                        //     'offeredBy': {
                        //         '@type': 'Organization',
                        //         'name':'Business Name'
                        //     },
                        //     'highPrice': 'Cost of yearly subscription',
                        //     'lowPrice': 'Low Number or leave as 0 if free',
                        //     'offerCount': 'How many on offer?',
                        //     'priceCurrency': 'USD',
                        //     'priceSpecification': [
                        //         {
                        //             '@type': 'UnitPriceSpecification',
                        //             'price': 'Leave as 0.00 if free, or enter low number',
                        //             'priceCurrency': 'USD',
                        //             'name': 'Lowest package name'
                        //         },
                        //         {
                        //             '@type': 'UnitPriceSpecification',
                        //             'price': 'Big number - if you're subscription based, use the 12 month cost',
                        //             'priceCurrency': 'USD',
                        //             'name': 'Highest/Premium package name',
                        //             'referenceQuantity': {
                        //                 '@type': 'QuantitativeValue',
                        //                 'value': '1',
                        //                 'unitCode': 'ANN'
                        //             }
                        //         },
                        //         {
                        //             '@type': 'UnitPriceSpecification',
                        //             'price': 'Price of monthly subscription',
                        //             'priceCurrency': 'USD',
                        //             'name': 'Subscription cost',
                        //             'referenceQuantity': {
                        //                 '@type': 'QuantitativeValue',
                        //                 'value': '1',
                        //                 'unitCode': 'MON'
                        //             }
                        //         }
                        //     ]
                        // },

                    })
                }
            }
            let MetaRobots;
            if(noFollow || noIndex) {
                const contents =  [];
                if(noIndex)  contents.push('noIndex')
                if(noFollow) contents.push('noFollow')
                MetaRobots = (<meta name="robots" content={contents.join(',')} />)
            }
            return (
                <>
                    <Helmet title={seo.title}
                            link={seo.url ? [{ rel: 'canonical', key: seo.url, href: seo.url }] : []}
                    >
                        <html lang={siteLanguage} />
                        {MetaRobots ? MetaRobots : ''}
                        <meta name='description' content={seo.description} />
                        <meta name='image' content={seo.image} />
                        <meta name='apple-mobile-web-app-title' content={shortName} />
                        <meta name='application-name' content={shortName} />
                        <script type='application/ld+json'>
                            {JSON.stringify(schemaOrgJSONLD)}
                        </script>

                        {/* OpenGraph  */}
                        <meta property='og:url' content={seo.url} />
                        <meta property='og:type' content={type ? type : 'website'} />
                        <meta property='og:title' content={seo.title} />
                        <meta property='og:description' content={seo.description} />
                        <meta property='og:image' content={seo.image} />

                        {/* Twitter Card */}
                        <meta name='twitter:card' content='summary_large_image' />
                        <meta name='twitter:creator' content={twitter} />
                        <meta name='twitter:title' content={seo.title} />
                        <meta name='twitter:description' content={seo.description} />
                        <meta name='twitter:image' content={seo.image} />

                        {/* Facebook  */}
                        <meta property="fb:app_id" content="1349816871829057" />
                        <meta name="facebook-domain-verification" content="79a0e5s91q5cba42b3gmm0jrybx341" />

                    </Helmet>
                </>
            )
        }}
    />
);
const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        title
        defaultTitle
        titleAlt
        shortName
        author
        siteLanguage
        logo
        siteUrl: url
        pathPrefix
        defaultDescription: description
        defaultBanner: banner
        twitter
      }
    }
  }
`;
export default SEO

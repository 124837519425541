import React, {useState} from 'react';
import { Link } from 'gatsby'
import starIcon from '../../assets/images/star-icon.png'
import ReceiptFaq from './ReceiptFaq'
import ReceiptMore from './ReceiptMore'
import {Accordion, AccordionItem, AccordionItemButton, AccordionItemHeading, AccordionItemPanel} from 'react-accessible-accordion';
import shape2 from '../../assets/images/services/service-shape2.png';
import WrapperStyle from './examples.style';
import OptimizeExample from './OptimizeExample';
import PopularExamples from './PopularExample';
import Drawer from '../../components/UiComponent/drawer'
import ScriptTag from 'react-script-tag';
import {StaticImage} from 'gatsby-plugin-image';
import Testimonials from '../Index/Testimonials';
import ClientECommerce from './ClientECommerce';

const OurSolutions = () => {
  const [visible, setVisible] = useState(false);
  const [promotionId, setPromotionId] = useState(null);
  const [drawerTitle, setDrawerTitle] = useState(null);
  const showDrawer = (id, title) => {
    setVisible(true);
    setPromotionId(id);
    setDrawerTitle(title)
  };
    return (
      <WrapperStyle>
        <section className="solutions-area pt-100 pb-70">
          <div className="container">
            <div className="section-title">
              <h1>ECサイトキャンペーン
              </h1>
              <p>
                お客様を引き付けるキャンペーンを実施する機会は非常に多くあります。１年を通して魅力的なキャンペーンを実施しタイミングを逃さないことが大切です。クーポン配布、試供品提供やLINEの友だち追加、購入者限定キャンペーン多くのキャンペーンを活用できます。
              </p>
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <a data-retach-widget="embed" href="https://retach.biz/promotions/727a0d43f5f831bcd2" rel="nofollow">リタッチ</a>
                <ScriptTag  type="text/javascript" src="https://retach.biz/scripts/promotionCall.js"/>
              </div>
            </div>
          </div>
        </section>
        {/*<Testimonials />*/}
        <ClientECommerce />
        <section className="ptb-100 top-question" >
          <div className="container">
            <div className="section-title">
                    <span className="sub-title">
                        <StaticImage src="../../assets/images/star-icon.png" alt="features" />
                        Question
                    </span>
              <h2 id="question">よくあるご質問</h2>
            </div>
            <div className="faq-accordion">
              <Accordion allowMultipleExpanded={true}>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      試供品の提供などに使えますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。応募方法のメール配信を「試供品の提供に同意」等に変更して頂き、同意した方に住所入力を表示してください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      TwitterのフォロワーやLINEの友だちを増やしたいのですがどうすればいいですか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      キャンペーンの賞品と引き換えに「Twitterのアカウントをフォローする」、「LINEの公式アカウントの友だち追加」ことを応募方法に設定してください。
                      拡散されるリツィートやシェア応募を組み合わせるとより効果的です。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      購入者のみに表示する際はどのようにすればいいですか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      注文完了ページやThankYouページのような、購入者のみに表示さえるページにコードを張り付けしてください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      どのECサイトに掲載できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      楽天やBase,Shoptify,Welcort,EC-CUBEなどHTML編集ができるサービスならほとんどが掲載可能です。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem>
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      応募者の住所や電話番号を取得できますか？
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      はい。応募者に入力して頂く項目は自由に変更できます。応募者情報の設定についてはこちらをご覧ください。
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </section>
        <OptimizeExample types={['password','repeatUp','photoContest',]} showDrawer={showDrawer}/>
        <PopularExamples exclude={['password','photoContent']} showDrawer={showDrawer}/>
        <Drawer visible={visible}id={promotionId} drawerTitle={drawerTitle} setVisible={setVisible}/>
      </WrapperStyle>
    )
};

export default OurSolutions